<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Nova Turma">
      <form-course action="add" :edit="false" :selectedCourse="selectedCourse"></form-course>
    </base-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    page: {
      title: 'Nova Turma'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Escolas',
        disabled: false,
        to: '/school'
      }
    ],
    id: ''
  }),
  computed: {
    ...mapState(['selectedCourse'])
  },
  methods: {
    async getSchoolData (organizationId) {
      try {
        const classResponse = await this.$axios.get(`organizations/${organizationId}/schools/${this.$route.params.id}`)

        if (!classResponse.data) {
          throw classResponse
        }

        this.school = classResponse.data
        // store.dispatch('setDataAuth', { token, classData })
      } catch (err) {
        const msg = 'Erro na conexão, tente novamente'
        this.$store.dispatch('alert', { color: 'red', msg })
      } finally {
        this.schoolFetch = true
      }
    }
  },
  async mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    await this.getSchoolData(this.id)
    this.breadcrumbs.push(
      {
        text: this.school.data.name,
        disabled: false,
        to: `/school/${this.$route.params.id}`
      },
      {
        text: 'Turmas',
        disabled: false,
        to: `/school/course/${this.$route.params.id}`
      },
      {
        text: this.page.title,
        disabled: false,
        to: `/course/add/${this.$route.params.id}/`
      }
    )
  }
}
</script>
